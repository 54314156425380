// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #1486BD;
$brand-highlight:       rgb(177,3,23);
$light-blue:       		rgb(163,216,231);
$dark-blue:				rgb(0, 81, 153);
$purple:				rgb(109,69,149);
$yellow:				rgb(251,184,31);
$light-yellow:			rgb(253,219,143);
$mid-green:				rgb(139,192,88);
$light-green:			rgb(218,233,201);
$pink:					rgb(214,59,150);
$orange:				rgb(229,59,24);
$dark-grey:				#575756;
$mid-grey:				#cccccc;
$light-grey:			rgb(245,245,244);
$white:					#FFFFFF;
$black:					#000000;

// Fonts
$serif:					'Times New Roman', serif;
$sans-serif:			'Open Sans', sans-serif;
