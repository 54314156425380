body{
	font-family: $sans-serif;
	font-weight: 300;
	color: $dark-grey;
	background-color: $light-grey;
	font-size:15px;
    height: 100%;
}
.brand {
    display: inline-block;
    height: 70px;
    margin: 15px 0;
    img {
	    display: block;
	    max-height: 100%;
	    max-width: 100%;
	    margin: 0 auto;
	}
}
.container{
	background-color: transparent;
}
.bg-b{
	background-color: $brand-primary;
}
.bg-w{
	background-color: $white;
}
.bg-g{
	background-color: $light-grey;
}
.container-fluid.bg-w {
    margin: 75px 0;
}
.sidebar-primary .main {
    float: left;
    width: 100% !important;
}
h1, .h1{
	font-size: 32px;
}
main ul li {
    margin: 12px 0;
}
.content img {
    height: auto;
    max-width: 100%;
}
.content .gm-style img{
	max-width: none;
}
img.pull-left,
img[style*="float:left"],
a.pull-left img,
p.pull-left img,
div.pull-left img {
    padding: 15px 15px 15px 0;
}
img.pull-right,
img[style*="float:right"],
a.pull-right img,
p.pull-right img,
div.pull-right img {
    padding: 15px 0 15px 15px;
}

/* NAV */
.navbar{
	margin-bottom: 0;
	border:0px none;
	min-height: 0;
}

/* APEX NAVBAR */
.primary-nav .navbar-apex .nav {
    float: right;
    min-height: 28px;
}
.primary-nav .navbar-apex, .primary-nav .navbar-apex a{
	color: $black;
	font-size: 14px;
}
.primary-nav .navbar-apex > li{
	display: inline-block;
}
.primary-nav .navbar-apex li > a{
    line-height: 100%;
    padding: 15px 0 12px 38px;
	position: relative;
	font-size: 0;
}

.primary-nav .navbar-apex > li > a > i {
    color: $black;
}
.primary-nav .navbar-apex > li > a:hover > i {
    color: $black;
}
.primary-nav .navbar-apex > li > a:focus, .navbar-apex li > a:hover {
    background-color: transparent;
    text-decoration: underline;
}
.primary-nav .navbar-apex > li.menu- > a {
    padding: 15px 10px 12px 0;
}
.primary-nav .navbar-apex > li.menu- > a i::before {
    font-size: 28px;
    line-height: 0;
    vertical-align: -8px;
}


.menu-newsletter-sign-up > a::after {
    color: #fff;
    content: "";
    display: block;
    font-family: FontAwesome;
    pointer-events:none;
    font-size: 25px;
    position: absolute;
    right: 0;
    top: 12px;    
}

/* PRIMARY NAVBAR */
.navbar-nav > li.active > a {
    position:relative;
}
.primary-nav .navbar-nav > li.active > a::after {
    bottom: 5px;
    content: "";
    display: block;
    left: 15px;
    position: absolute;
    width: calc(100% - 30px);
}


/* DROPDOWN MENU */
.navbar-primary .dropdown ul.dropdown-menu{
	border-left: 4px solid $brand-primary;
}



.navbar-primary {
    font-size: 18px;
    min-height: 105px;
    .nav{
	    > li {
	    	> a{
				color: $black;
				font-weight:600;
			}
			> a:focus, > a:hover {
			    background-color: $brand-primary;
			    text-decoration: none;
			    color: $white;
			}
			.dropdown-menu{
				> li{
					a:focus, a:hover{
						background-color: $brand-primary;
    					color: $white;
					}
				}
			}
	    }
		
	}
}

.page-panel{
	text-align:center;
}
.intro-panel .info-panel.page-panel {
    text-align: center;
}
.intro-panel.row {
    margin-bottom: 45px;
}
.page-child .intro-panel .info-panel.page-panel, .template-section-alt .intro-panel .info-panel.page-panel{
    height: unset;
    min-height: 110px;
    margin: 0 0 80px;
    padding-bottom: 40px;
    overflow: visible;
    position: relative;
}

/* MOBILE NAVBAR */
.navbar-collapse.in{
	margin-bottom:20px;
}
.navbar-toggle {
    border-radius: 0;
    border-color: transparent;
    left: 0;
    position: absolute;
    top: 0;
    margin-top: 0;
    .icon-bar{
	    background-color: $white;
	    border-radius: 0;
	    display: block;
	    height: 5px;
	    width: 30px;
	}
}
.navbar-toggle:hover {
    .icon-bar{
	    background-color: $white;
	}
}
.navbar-header{
	position: static;
}

header {
    position: relative;
}

.page-header {
    border-bottom: 0 none;
    margin: 0;
    padding: 0;
}
.ss-container {
    border-top: 4px solid $brand-highlight;
    position: relative;
}

.header-contact{
	p{ 
		margin:0;
	}
	a, a:visited, a:hover{
		font-size:18.5px;
	}
	.tel {
	    font-size: 30px;
	    line-height: 100%;
	    padding-top: 4px;
	    margin-bottom: -2px;
	}
} 

/* CAROUSEL */
.carousel-caption {
    font-size: 15px;
    left: auto;
    line-height: 120%;
    max-width: 50%;
    position: absolute;
    right: 15px;
    text-align: right;
    top: 0;
	h4{
		display:none;
	}
}
.carousel-indicators {
    display:none;
}
.carousel-inner .item {
    height: 200px !important;
}

.glyphicon-chevron-right::before{
	content: "";
	font-family: FontAwesome;
}
.glyphicon-chevron-left::before{
	content: "";
	font-family: FontAwesome;
}
.carousel-control{
	display:none;
} 
.page-banner {
    width: 100%;
    position: relative;
}
.banner.affix {
    min-height: 40px;
    z-index: 9999;
    width: 100%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.admin-bar .banner.affix {
	top: 0;
}
.banner.affix + .wrap {
    padding-top: 152px;
}

/* INFO PANELS */
.info-panel {
    background-color: #fff;
    border-radius: 20px;
    margin: 60px 0 0;
    padding: 15px 25px 40px;
    postition:relative;

    .img-responsive {
	    display: block;
	    margin-left: auto;
	    margin-right: auto;
	    max-width: 85%;
	}
	img, h2 {
	    margin-bottom: 25px;
	}
	.btn-panel {
	    display: block;
	    position: absolute;
	    width: 60px;
	    height: 60px;
	    left: 50%;
	    margin-left: -30px;
	    bottom: -30px;
	    border-radius: 30px;
	}
	.btn-panel::after {
	    bottom: 0;
	    color: #fff;
	    content: "";
	    font-family: FontAwesome;
	    font-size: 35px;
	    left: 0;
	    line-height: 55px;
	    padding: 1px 0 0 3px;
	    position: absolute;
	    right: 0;
	    text-align: center;
	    top: 0;
	}
}
main ul {
	list-style-type: none;
	position: relative;
	margin-left: 12px;
	padding-left: 0;
}
main li:before {
	content: "\2022";
	position: absolute;
	left: -12px;
	color: $brand-primary;
	margin-right: 5px;
}

/* NEWS PANELS */
.news-panel {
    display: block;
    margin-top: 35px;
    margin-bottom: 80px;
    > h2 {
	    margin-bottom: 40px;
	    text-align: center;
	    color: $brand-primary;
	}
}
.news-img {
    background-position: center center;
    background-size: cover;
    display: block;
    margin: 30px 0 10px;
    min-height: 180px;
    width: 100%;
}
.news-item{
	a {
	    color: $brand-primary;
	}
	h4 > a {
	    color: $purple;
	}
}
.pull-left.news-list-img {
    max-height: 190px;
    padding-top: 5px;
    width: auto;
}
.section-panel{
    display: block;
    margin: 80px 0;
}

.modal-header {
    background-color: $brand-primary;
    color: $white;
}

/* DIVISION/SECTION BRANDING */
.ll-div-gen:nth-child(odd), .col-md-4:nth-child(odd) .page-panel, .page-child .col-md-4 .page-panel:nth-child(odd){
	li:before, h2, h3, h3 a{
		color: $brand-primary;
	}
	.btn-panel{
	    background-color: $purple;
	}
} 
.ll-div-gen:nth-child(even), .col-md-4:nth-child(even) .page-panel, .page-child .col-md-4 .page-panel:nth-child(even){
	li:before, h2, h3, h3 a{
		color: $purple;
	}
	.btn-panel{
	    background-color: $brand-primary;
	}
}
.ll-div-bus:nth-child(odd), .adult .col-md-4:nth-child(odd) .page-panel, .adult .section-panel, .page-child .adult .col-md-4 .page-panel:nth-child(odd){
	li:before, h2, h3, h3 a{
		color: $mid-green;
	}
	.btn-panel{
	    background-color: $mid-green;
	}
}
.ll-div-bus:nth-child(even), .adult .col-md-4:nth-child(even) .page-panel, .adult .section-panel, .page-child .adult .col-md-4 .page-panel:nth-child(even){
	li:before, h2, h3, h3 a{
		color: $orange;
	}
	.btn-panel{
	    background-color: $orange;
	}
}.ll-div-bus:nth-child(odd), .business .col-md-4:nth-child(odd) .page-panel, .business .section-panel, .page-child .business .col-md-4 .page-panel:nth-child(odd){
	li:before, h2, h3, h3 a{
		color: $brand-primary;
	}
	.btn-panel{
	    background-color: $brand-primary;
	}
}
.ll-div-bus:nth-child(even), .business .col-md-4:nth-child(even) .page-panel, .business .section-panel, .page-child .business .col-md-4 .page-panel:nth-child(even){
	li:before, h2, h3, h3 a{
		color: $dark-blue;
	}
	.btn-panel{
	    background-color: $dark-blue;
	}
}
.ll-div-yth:nth-child(odd), .youth .col-md-4:nth-child(odd) .page-panel, .youth .section-panel, .page-child .youth .col-md-4 .page-panel:nth-child(odd){
	li:before, h2, h3 a{
		color: $pink;
	}
	.btn-panel{
	    background-color: $yellow;
	}
}
.ll-div-yth:nth-child(even), .youth .col-md-4:nth-child(even) .page-panel, .youth .section-panel, .page-child .youth .col-md-4 .page-panel:nth-child(even){
	li:before, h2, h3, h3 a{
		color: $yellow;
	}
	.btn-panel{
	    background-color: $pink;
	}
} 
.partner {
    margin-bottom: 15px;
}
.partner > a {
    display: block;
    height: 65px;
}
.partner img {
    height: auto;
    margin: 0 auto;
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
}
.content{
	margin-top: 75px;
}
.page-child .content {
    margin-top: 30px;
}
.content > div > p:first-child{
	font-size: 120%;
}


/* FOOTER */
footer {
    margin-top: 75px;
	.footer{
	    padding: 40px 0 30px;
	    font-size: 85%;
	    color: $black;
	    a{
		    color: $black;
		    margin-right: 10px;
		}
		a:hover, a:focus, a:active{
		    color: $white;
		}
		.fa {
		    color: #fff;
		    margin: 0 7px 0 0;
		    vertical-align: baseline;
		}
	}
}
.gallery img {
    border: medium none !important;
    height: auto !important;
    max-width: 100%;
    padding: 0 7px;
}
.gallery .gallery-item {
    margin-top: 12px;
}
.gallery dl{
	margin: 0;
}

.slide-overlay {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    display: block;
    height: 550px;
    left: 0;
    overflow: visible;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
}

.page-child .col-md-6 > .embed-responsive, .page-child .col-md-6 > iframe {
    margin: 40px 0 25px;
}
.page-child .col-md-6{
	margin-bottom: 15px;
}
.banner-overlay {
    padding: 15px;
    text-align: center;
}
#map-container{
	height: 100%;
}
.mobile-contact-map{
	width: 100%;
	height: 400px;
}
.location-map{
	width: 100%;
	height: 500px;
}

.panel-heading{
	padding: 0;
}
.panel-heading .panel-title a, .panel-heading h3.panel-title{
    display: block;
    padding: 10px 15px;
    width: 100%;
}
.panel-title > .small, .panel-title > .small > a, .panel-title > a, .panel-title > small, .panel-title > small > a{
	color: $brand-primary;
}


.container-fluid.bg-b {
    position: relative;
}

.ninja-forms-all-fields-wrap {
    background-color: #dbdbdb;
    margin: 15px 0;
    padding: 15px;
}

.staff-profile{
	margin-bottom: 15px;
	margin-top: 15px;
}
.staff-img {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 270px;
    min-height: 290px;
}
.staff-profile:nth-of-type(3n) {
    clear: right;
}
.twitter-feed > div{
	padding: 20px 5px;
	color: $white;
}
.twitter > ul{
	list-style: none;
	margin: 15px 0;
	padding: 0;
}
.twitter > ul > li:first-child {
    border-top: 1px solid $black;
}
.twitter > ul > li {
    border-bottom: 1px solid $black;
    color: $white;
    padding: 15px 0;
}
.twitter a{
	color: $black;
}
.twitter li a{
	color: $white;
}
.single-post .wrap header img {
    margin: 20px 0;
}

div.wpbs-calendar{
	border-radius: 5px;
}
.wpbs-container .wpbs-legend .wpbs-legend-item p{
	font-size: 12px;
	line-height: 15px;
}
.wpbs-container .wpbs-legend .wpbs-legend-color{
	height: 15px;
	width: 15px;
}
wpbs-form{
	padding: 0 5px;
}
.wpbs-widget .wpbs-legend {
    padding: 3px 0 0 !important;
}
.wpbs-form-form .wpbs-form-item label.wpbs-form-label {
    float: left !important;
    text-align: right;
    width: 84px;
    line-height: 1.2 !important;
}
.wpbs-form-form .wpbs-form-item input {
    float: right !important;
    margin-left: 15px;
    width: 150px !important;
}
.wpbs-form .wpbs-form-item .wpbs-form-submit{
	background-color: $brand-primary !important;
	margin-top: 0 !important;
    border: 0px none !important;
    color: $white !important;
}

/* media queries */
@media (max-width: $screen-xs-min) {
	.carousel-caption .btn {
	    background-color: $brand-primary;
	    border: 0 none;
	    border-radius: 0;
	    bottom: -30px;
	    height: 30px;
	    left: 0;
	    padding: 4px 15px;
	    position: absolute;
	    text-align: right;
	    width: 100%;
	}
	.carousel-inner .item{
		margin-bottom: 30px;
	}
	.carousel-caption {
	    float: right;
	    margin-right: 15px;
	    margin-bottom: 30px;
	    position: static;
	}
	.slide-overlay{
		bottom: 30px;
	}
	.news-panel{
		margin-top: 0;
		h2{
			margin-bottom: auto;
		}
	}
	.news-item p, .info-panel ul, .info-panel p{
		display: none;
	}
	.brand {
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
    	transition: all 0.35s;
	}
	.affix .brand {
	    height: 40px;
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
    	transition: all 0.35s;
	}
	.intro-panel .info-panel{
		height: auto;
	}
	.navbar-primary .dropdown ul.dropdown-menu{
		border-radius: 0px;
	}
	.container.primary-nav {
	    height: 100%;
	    overflow-y: auto;
	}
}
@media (max-width: 767px) {
	.ss-container .page-banner{
		background-image: none !important;
	}
}
@media (min-width: $screen-sm-min) {
	.primary-nav .navbar-nav > li.active > a::after {
	    bottom: 12px;
	}
	.page-banner {
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: cover;
	    min-height: 380px;
	}
	.banner-overlay {
	    color: #fff;
	    line-height: 1.6;
	    max-width: 85%;
	    position: absolute;
	    left: 50%;
	    top: 20px;
	    width: 635px;
	    text-align:left;
	}
	.carousel-control{
	z-index: 999;
	display:block;
		.glyphicon-chevron-left, .icon-prev{
			left: 15px
		}
		.glyphicon-chevron-right, .icon-next{
			right: 15px
		}
		.glyphicon-chevron-left, .glyphicon-chevron-right, .icon-next, .icon-prev{
			font-size: 40px;
			top: 44%;
		}
	} 

	.affix .navbar-primary {
	    min-height: 50px;
		-webkit-transition: all 0.35s;
		-moz-transition: all 0.35s;
    	transition: all 0.35s;
	}
	.affix .brand {
	    display: inline-block;
	    height: auto;
	    margin: 5px 0 0 15px;
	}
	.affix .brand img {
	    display: block;
	    height: 50px;
	    width: auto;
		-webkit-transition: height 0.35s;
		-moz-transition: height 0.35s;
    	transition: height 0.35s;
	}
	.affix .navbar-primary .nav > li > a {
	    margin-top: 4px;
	}
	.dropdown-menu{
		border-top: 0px none;
		border-right: 0px none;
		border-bottom: 0px none;
		padding: 0;
	}
	.dropdown-menu > li:first-child > a {
	    padding-top: 8px;
	}
	.dropdown-menu > li:last-child > a {
	    padding-bottom: 8px;
	}
	.navbar-apex li.menu- > a{
		display:block;
	}
	.navbar-apex li > a{
		font-size: inherit;
	    padding: 5px 15px;

	}
	.brand {
	    height: 60px;
	    margin: 25px 0 0 15px;
	    img {
	    	margin: 0;
			-webkit-transition: height 0.35s;
			-moz-transition: height 0.35s;
	    	transition: height 0.35s;
		}
	}
	
	.menu-newsletter-sign-up{
		padding-right: 5px;
	}
	.menu-newsletter-sign-up > a::after {
	    font-size: 18px;
	    left: -9px;
	    top: 4px;
	}

	.primary-nav .navbar-nav > li > a {
	    padding: 16px 10px 18px;
	}
	.navbar-primary .nav {
	    float: right;
	    > li > a {
		    margin-top: 30px;
		}
	}
	.navbar-primary .dropdown ul.dropdown-menu{
		border-left-width: 2px;
	}
	.navbar-apex li.menu- > a i::before {
	    font-size: 18px;
	    vertical-align: -5px;
	}
	.primary-nav .navbar-apex > li.menu- > a {
	    padding: 0 0 0 10px;
	}
	.navbar-apex{
		height: 24px;
		overflow: hidden;
	}
	.navbar-apex .nav {
	    min-height: 0;
	}
	.admin-bar .banner.affix {
		top: auto;
	}
	.carousel-inner .item {
	    height: 550px !important;
	}
	.carousel-caption{
	    font-size: 26px;
	    left: auto;
	    right: 10vw;
	    top: 20px;
	    width: 400px;
	    max-width: 90%;
		h4{
			display:none;
		}
		.btn-default {
		    background-color: $brand-primary;
		    border: medium none;
		    border-radius: 18px;
		    color: $black;
		    font-size: 15px;
		    font-weight: 700;
		    padding: 14px 35px;
		    margin-top: 20px;
		}
		.btn-default:hover, .btn-default:active:focus {
		    background-color: $purple;
		    color: $white;
		}
	}
	.partner > a {
	    display: block;
	    height: 100px;
	}
	.news-img {
	    margin: 0 0 40px;
	    min-height: 240px;
	}
	.navbar-collapse.in {
	    margin-bottom: 0px;
	}
	.carousel-caption, .banner-overlay{
		left: 40%;
	}
	.banner-overlay{
		width: 450px;
	}
}
@media (min-width: $screen-md-min) {
	.footer .col-md-4{
		text-align:right;
	}
	.intro-panel .info-panel {
	    height: 400px;
	    overflow: hidden;
	}
	.intro-panel .info-panel.page-panel {
	    height: 340px;
	}
	.vertical-align {
	  display: table;
	  table-layout: fixed;
	  height: 100%;
	  width: 100%;
	  margin-bottom: 15px;
	}
	.vertical-align > .col-md-6, .vertical-align > .col-md-4 {
	  display: table-cell;
	  float: none;
	  height: 100%;
	  vertical-align: middle;
	}
	.vertical-align .twitter-feed {
	    vertical-align: unset;
	}
	.primary-nav .navbar-nav > li > a {
	    padding: 16px 15px 18px;
	}
	.navbar-primary {
	    min-height: 140px;
		.nav {
		    float: right;
		    > li > a {
			    margin-top: 60px;
			}
		}
	}
	.brand {
	    height: 90px;
	}
	.carousel-caption, .banner-overlay{
		left: 45%;
	}
	.banner-overlay{
		width: 530px;
	}
	.staff-img {
	    background-position: center top;
	    background-repeat: no-repeat;
	    background-size: cover;
	    max-width: 100%;
	    min-height: 260px;
	}
	.staff-profile:nth-of-type(2n) {
	    clear: right;
	}
}
@media (min-width: $screen-lg-min) {
	.primary-nav .navbar-nav > li > a {
	    padding: 16px 20px 18px;
	}
	.carousel-caption, .banner-overlay{
		left: 48%;
	}
	.banner-overlay{
		width: 610px;
	}
	.slide-overlay{
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: auto auto;
	}
}
@media (min-width: 1440px) {
	.container {
	    /* width: 1400px; */
	}
	.ss-container {
	    .page-banner{
		    height:420px;
	    }
	}
}